<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id，zb_user.id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id，zb_user.id" />
      </a-form-model-item>
      <a-form-model-item label="性别,0-保密 1-男 2-女" prop="sex" >
      </a-form-model-item>
      <a-form-model-item label="生日，格式1990-04-22" prop="birthday" >
      </a-form-model-item>
      <a-form-model-item label="年龄" prop="age" >
        <a-input v-model="form.age" placeholder="请输入年龄" />
      </a-form-model-item>
      <a-form-model-item label="婚姻状态id,sys_dict_data.id" prop="marriageId" >
        <a-input v-model="form.marriageId" placeholder="请输入婚姻状态id,sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="民族id,sys_dict_data.id" prop="nationId" >
        <a-input v-model="form.nationId" placeholder="请输入民族id,sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="身高" prop="height" >
        <a-input v-model="form.height" placeholder="请输入身高" />
      </a-form-model-item>
      <a-form-model-item label="体重" prop="weight" >
        <a-input v-model="form.weight" placeholder="请输入体重" />
      </a-form-model-item>
      <a-form-model-item label="星座id，sys_dict_data.id" prop="constellationId" >
        <a-input v-model="form.constellationId" placeholder="请输入星座id，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="是否体制内,0-否 1-是" prop="isSystem" >
        <a-input v-model="form.isSystem" placeholder="请输入是否体制内,0-否 1-是" />
      </a-form-model-item>
      <a-form-model-item label="职业id，sys_dict_data.id" prop="careerId" >
        <a-input v-model="form.careerId" placeholder="请输入职业id，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="学历id，sys_dict_data.id" prop="educationId" >
        <a-input v-model="form.educationId" placeholder="请输入学历id，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="月收入id，sys_dict_data.id" prop="incomeId" >
        <a-input v-model="form.incomeId" placeholder="请输入月收入id，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="月收入值，字段表值，修改同步修改" prop="incomeValue" >
        <a-input v-model="form.incomeValue" placeholder="请输入月收入值，字段表值，修改同步修改" />
      </a-form-model-item>
      <a-form-model-item label="现居地" prop="currentAddress" >
        <a-input v-model="form.currentAddress" placeholder="请输入现居地" />
      </a-form-model-item>
      <a-form-model-item label="户籍地" prop="address" >
        <a-input v-model="form.address" placeholder="请输入户籍地" />
      </a-form-model-item>
      <a-form-model-item label="购车id，sys_dict_data.id" prop="buyCarId" >
        <a-input v-model="form.buyCarId" placeholder="请输入购车id，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="购房id，sys_dict_data.id" prop="buyHouseId" >
        <a-input v-model="form.buyHouseId" placeholder="请输入购房id，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="期望结婚时间id，sys_dict_data.id" prop="expectMarriedId" >
        <a-input v-model="form.expectMarriedId" placeholder="请输入期望结婚时间id，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="是否要孩子id，sys_dict_data.id" prop="exceptChildId" >
        <a-input v-model="form.exceptChildId" placeholder="请输入是否要孩子id，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="吸烟id，sys_dict_data.id" prop="smokeId" >
        <a-input v-model="form.smokeId" placeholder="请输入吸烟id，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="喝酒id，sys_dict_data.id" prop="drinkId" >
        <a-input v-model="form.drinkId" placeholder="请输入喝酒id，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="兴趣爱好id，多个以英文逗号分割，sys_dict_data.id" prop="interestId" >
        <a-input v-model="form.interestId" placeholder="请输入兴趣爱好id，多个以英文逗号分割，sys_dict_data.id" />
      </a-form-model-item>
      <a-form-model-item label="审核状态0待审核1审核通过2审核驳回" prop="approveStatus" >
      </a-form-model-item>
      <a-form-model-item label="审核失败原因" prop="approveContent" >
        <editor v-model="form.approveContent" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getBaseInfo, addBaseInfo, updateBaseInfo } from '@/api/user/baseInfo'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        sex: null,

        birthday: null,

        age: null,

        marriageId: null,

        nationId: null,

        height: null,

        weight: null,

        constellationId: null,

        isSystem: null,

        careerId: null,

        educationId: null,

        incomeId: null,

        incomeValue: null,

        currentAddress: null,

        address: null,

        buyCarId: null,

        buyHouseId: null,

        expectMarriedId: null,

        exceptChildId: null,

        smokeId: null,

        drinkId: null,

        interestId: null,

        approveStatus: 0,

        approveContent: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id，zb_user.id不能为空', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '性别,0-保密 1-男 2-女不能为空', trigger: 'change' }
        ],
        age: [
          { required: true, message: '年龄不能为空', trigger: 'blur' }
        ],
        height: [
          { required: true, message: '身高不能为空', trigger: 'blur' }
        ],
        weight: [
          { required: true, message: '体重不能为空', trigger: 'blur' }
        ],
        isSystem: [
          { required: true, message: '是否体制内,0-否 1-是不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        sex: null,
        birthday: null,
        age: null,
        marriageId: null,
        nationId: null,
        height: null,
        weight: null,
        constellationId: null,
        isSystem: null,
        careerId: null,
        educationId: null,
        incomeId: null,
        incomeValue: null,
        currentAddress: null,
        address: null,
        buyCarId: null,
        buyHouseId: null,
        expectMarriedId: null,
        exceptChildId: null,
        smokeId: null,
        drinkId: null,
        interestId: null,
        approveStatus: 0,
        approveContent: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBaseInfo({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBaseInfo(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBaseInfo(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
